import {Container,Row, Col, Image} from 'react-bootstrap';
import WordMark from '../../assets/wordmark-stack.svg'
import BotRight from '../../assets/hero-bot-right.svg'
import Button2022 from '../../assets/button-2022.svg'
import './HeroBanner.css';

function HeroBanner() {
  let heroStyle = {
    background: `linear-gradient(192.43deg, #2B2B2F 26.42%, #16161B 90.97%)`,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    width: "auto"
// padding: "0px 200px 0px 0px",
  }
  let wordMarkStyle = {
    display:"block",
    margin:"auto",
    color: "white",
    pointerEvents:"none"
  }
  let botRightStyle = {
    // display:"block",
    // margin:"auto",
    pointerEvents:"none",
  }
  let button2022Style = {
    paddingTop: "2rem",
    display:"block",
    margin:"auto",
    pointerEvents: "none"
  }
  return (
<Container className="hero-container" style={heroStyle} fluid>
  <Row className="centered" style={{height:"auto"}}>
    <Col style={wordMarkStyle} className="hero-logo col-lg-4 col-md-5 mx-auto col-10">
      <Row className="pb-5">
        <img src={WordMark} style={wordMarkStyle}></img>
      </Row>
      <Row className="col-12 mx-auto">
        <div className="join-drop-zero p-3">
          Coming 2022
        </div>
      </Row>
    </Col>
    <Col className="col-lg-4 col-md-5 mx-auto col-sm-8" style={{"width":"auto"}}>
      <Image style={botRightStyle} src={BotRight} className="hero-image"></Image>
    </Col>
  </Row>
</Container>

  );
}

export default HeroBanner;
