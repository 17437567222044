import {Container, Stack} from 'react-bootstrap';
import { ReactComponent as BotLogo } from '../../assets/bot-logo.svg'
import { ReactComponent as TwitterLogo } from '../../assets/twitter-logo.svg'


function Footer() {
  let footerStyle = {
    backgroundColor:"#AAFF3E",

  }
  let BotStack = {
    paddingTop: "5rem",
  }
  let SocialStack = {
    padding: "1rem"
  }
  let cRightFont = {
    fontFamily: "Jura"
  }
  return (
<Container style={footerStyle} fluid>


<Stack style={BotStack} direction="horizontal" gap={0} className="d-flex justify-content-center mb-2" fluid>
  <BotLogo />
  </Stack>
  <Stack style={SocialStack} direction="horizontal" gap={4} className="d-flex justify-content-center footer-social" fluid>
    <a href="https://twitter.com/lostbots_NFT">
    <TwitterLogo fill="black" height="24" width="24"/>

    </a>

  </Stack>
  <Stack direction="horizontal" className="d-flex justify-content-center" fluid>
  <span style={cRightFont}>
  ©2022 ROM Designs, LLC
    </span>
  </Stack>



</Container>

  );
}

export default Footer;
