import {Container,Row, Col, Navbar, Nav} from 'react-bootstrap';
import { ReactComponent as BotLogo } from '../../assets/bot-logo.svg'
import { ReactComponent as TwitterLogo } from '../../assets/twitter-logo.svg'

function NavBar() {
  let navStyle = {
    // height: "3rem",
    width: "100%",
    padding: 0
  }
  let BotLogoStyle = {
    width: "30px",
    height: "30px"
  }
  let twitterLogoStyle = {
    color: "white"
  }
  return (
<Container style={navStyle} fluid>
  <Row>
    <Col ><Navbar fixed="top" bg="dark" className="bg-opacity-75 pt-3 pb-3">
  <Container>
    <Navbar.Brand href="#home">
      {/* <img
        src={BotLogo}
        width="30"
        height="30"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
        style={BotLogoStyle}
      /> */}
      <div>
      <BotLogo fill="white" height="30px" width="30px"/>
      </div>

    </Navbar.Brand>
    <Nav>
    <Navbar.Brand href="https://twitter.com/lostbots_NFT">
      {/* <img
        src={TwitterLogo}
        width="30"
        height="30"
        className="d-inline-block align-top"
        alt="twitter logo"
        style={twitterLogoStyle}
      /> */}
            <div>
      <TwitterLogo fill="white" />
      </div>
    </Navbar.Brand>
    </Nav>
  </Container>
  </Navbar></Col>
  </Row>
</Container>

  );
}

export default NavBar;
