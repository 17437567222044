import {Container,Row, Col, Card, Stack, Button} from 'react-bootstrap';
import { ReactComponent as JoinDropZero } from '../../assets/join-drop-zero.svg'
import './InfoBar.css'

function InfoBar() {
  let infoStyle = {
    backgroundColor:"#070707",
    paddingTop: "5rem",
    paddingBottom: "5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem"
  }
  let infoTextStyle= {
    color:"white",
    marginTop: "3rem",
    textAlign: "center",
    fontFamily: "Jura",
    weight: 600
  }
  let infoBotNumberStyle= {
    color:"white",
    textAlign: "left",
    fontWeight: 800,
    fontSize: "40px",
    lineHeight: "40px",
    letterSpacing: "0em",
    fontFamily: "Gemunu Libre"

  }
  let infoEthNumberStyle= {
    color:"white",
    textAlign: "right",
    fontWeight: 800,
    fontSize: "40px",
    lineHeight: "40px",
    letterSpacing: "0em",
    fontFamily: "Gemunu Libre"
  }
  let infoBotLabelStyle= {
    color:"white",
    textAlign: "left",
    fontFamily: "Gemunu Libre"
  }
  let infoEthLabelStyle= {
    color:"white",
    textAlign:"right",
    fontFamily: "Gemunu Libre",
  }
  let infoDividerStyle= {
    color:"white",
    height: "100%",
    width: "1px"
  }
  let joinDropZeroStyle = {
    fontFamily: "Gemunu Libre",
fontSize: "16px",
fontStyle: "normal",
fontWeight: 800,
lineHeight: "24px",
letterSpacing: "0.32em",
textAlign: "center"
  }
  let joinDropZeroStackStyle= {
    margin: "3rem"
  }
  return (
<Container style={infoStyle} fluid>
  <Stack direction="horizontal" gap={2} className="col-lg-3 col-md-6 mx-auto col-sm-5 col-10">
    <Stack className="col-lg-2 col-md-4 col-sm-2 col-1">
      <div style={infoBotNumberStyle}>5000</div>
      <div style={infoBotLabelStyle}>BOTS</div>
    </Stack>
    <Stack style={{"max-width":"1px"}}>
  {/* <div style={infoDividerStyle}>|</div> */}
      <div className="vr" style={infoDividerStyle}/>
    </Stack>
    <Stack className="col-lg-2 col-md-4 col-sm-2 col-1">
      <div style={infoEthNumberStyle}>0.05</div>
      <div style={infoEthLabelStyle}>ETH</div>
    </Stack>
  </Stack>
  <Stack className="col-lg-6 col-md-10 mx-auto col-sm-12 col-12" style={infoTextStyle}>


  The LOST BOTS is a collection of 5,000 generated bot avatar NFTs. The project is the brainchild of our resident multi-disciplinary Artist, ROM and his crew of visionaries. Each Bot is equipped with its own story and is meant to represent a blend of styles and unique personalities seen across sneaker and street wear communities.<br></br>
<br></br>
After building this project in stealth mode for months, our Bots are ready for the next phase in their journey. Our mission is to build a community for creatives across all disciplines to collaborate with and help us build the LOST BOTS universe, using web3 technology.



</Stack>
  <Stack  className="col-lg-3 col-md-6 mx-auto col-sm-12 col-12" style={joinDropZeroStackStyle}>
   <div className="join-drop-zero p-3" onClick={()=> window.open("https://forms.gle/TQUAmg3anbtnUXZTA", "_blank")}>
       {/* <JoinWhiteList style={{width:"100%"}}></JoinWhiteList> */}
       Register

   </div>
  </Stack>
</Container>

/* <Container style={infoStyle} fluid>
  <Row>
    <Col>
    <Card bg="dark" text="white">
    <Card.Title className="text-center">5000 | 0.05</Card.Title>
    <Card.Subtitle className="mb-2 text-muted text-center">Bots | ETH</Card.Subtitle>
  <Card.Body className="text-center">The LOST BOTS is a collection of 7,000 randomly generated bot avatar NFTs on the Ethereum blockchain. Your Lost Bot doubles as your membership card to the LOST BOTS community and it grants you access to premium benefits like giveaways, the community fund, licensing payouts, and more.</Card.Body>

</Card></Col>
  </Row>
</Container>
 */






  );
}

export default InfoBar;
