import logo from './bot-logo.svg';
import wordmark from './wordmark-stack.svg';
import './App.css';
import NavBar from './components/NavBar/NavBar';
import HeroBanner from './components/HeroBanner/HeroBanner'
import InfoBar from './components/InfoBar/InfoBar';
import Footer from './components/Footer/Footer';
import {Container} from 'react-bootstrap';

function App() {
  return (
    <div className='App'>
      <Container className="p-0" fluid>
      <NavBar/>
<HeroBanner/>
<InfoBar/>
<Footer/>
      </Container>


    </div>

  );
}

export default App;
